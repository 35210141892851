import { Swiper, SwiperSlide } from "swiper/react";
import { Controller } from 'swiper';
import { useLocation, Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import fslightbox from "fslightbox"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { chunkIntoN, fmtDate } from "../../util";

const FETCH_QUERY = gql`
query MyQuery ($slug: String) {
    gallery(slug: $slug) {
        title
        createdAt
        pics {
            id
            isMain
            pic
            clipId
            clipHosting
        }
    }
    galleries(pagination: {offset: 0, limit: 10}) {
      id
      createdAt
      title
      slug
      itemCount
      pics {
          isMain
          pic
      }
    }
  }
`

const BASE_URLS = {
    'youtube': 'https://www.youtube.com/watch?v=',
    'dzen.ru': 'https://dzen.ru/embed/',
}

function Item({item}) {
    let baseUrl = BASE_URLS[item.clipHosting]
    let href
    let dzen = item.clipHosting == 'dzen.ru'
    if (dzen) {
        href = "#dzen-clip" + item.id
    } else {
        href = item.clipId? baseUrl+item.clipId: '/media/' + item.pic
    }

    return <a data-fslightbox="m" {...(dzen ? {'data-class': 'fslightbox-source'} : {})} 
            href={href} className="g-post__row">
        <img src={item.clipId? item.pic : '/media/' + item.pic} alt="" style={dzen? {border: "1px solid #E1ECEF"}: {}} />
    </a>
}

function MaterialsIn() {
    let {slug} = useParams();
    let navigate = useNavigate()
    const { data } = useQuery(FETCH_QUERY, {
        variables: {slug}
    });

    let gallery = data?.gallery

    

    let cols = chunkIntoN(gallery?.pics || [], 3)

    useEffect(()=> {
        if (window.fsLightbox) {
            window.refreshFsLightbox()
        } else {
            setTimeout(()=> {
                window.fsLightbox = new window.FsLightbox()
                window.refreshFsLightbox()
            }, 500)
        }
    }, [slug])

    const [gallerySwiper, setGallerySwiper] = useState(null)

    return (
        <>
            <link rel="stylesheet" href="/assets/css/media/materials-in.css"/>
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <Link to="/" className="g-bc__item">главная</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <Link to="/gdu-media" className="g-bc__item">медиа</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <Link to="/gdu-media/materials" className="g-bc__item">Фото и видео материалы</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            {/* <p className="g-bc__item">{data?.post.title}</p> */}
                        </div>
                    </div>
                </div>
                
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                            <div className="g-post__data g-dfw">
                                <h2 className="g-post__title">{gallery?.title}</h2>
                                <div className="g-pd__item g-dfw">Альбом · {new Date(gallery?.createdAt).toLocaleDateString()}</div>
                            </div>
                            {/* <div className="g-post__actions g-dfw">
                                <a href="#" className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.375 10.6875L15.75 7.3125L12.375 3.9375" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M13.5 15.1875H2.8125C2.66332 15.1875 2.52024 15.1282 2.41475 15.0227C2.30926 14.9173 2.25 14.7742 2.25 14.625V6.1875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M5.27515 12.375C5.64978 10.926 6.49513 9.64256 7.67842 8.72623C8.86171 7.8099 10.3159 7.31261 11.8125 7.3125H15.75" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                    
                                </a>
                                <a href="#" className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.04688 7.73535L9 10.6877L11.9531 7.73535" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9 2.8125V10.6854" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </a>
                            </div> */}
                            {cols.map(col => <div className="g-post__col">
                                {col.map(item => <Item key={item.id} item={item} />)}
                            </div>)}
                        
                    </div>
                </div>
            </section>
            {/* <!-- Photo & Video Section --> */}
            <section className="g-section g-galary__section">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Другие фото и видео материалы</h2>
                        <div className="g-section__actions g-dfw">
                            <a href="/gdu-media/materials" className="g-sa__link">Смотреть все</a>
                            <div className="g-galary__slider__navigation g-slider__navigation g-dfw">
                                <div onClick={()=> gallerySwiper.slidePrev()} className="g-bsn__btn prev">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                                <div onClick={()=> gallerySwiper.slideNext()} className="g-bsn__btn next">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                            </div>
                        </div>

                        <Swiper className='g-slider g-galary__slider'
                            slidesPerView={2}
                            spaceBetween={10}
                            loop={true}
                            modules={[Controller]}
                            onSwiper={setGallerySwiper}

                            breakpoints={{
                                992: {
                                    slidesPerView: 6,
                                    spaceBetween: 15,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 15,
                        
                                }
                            }}
                        >
                            {data?.galleries.map((item,idx) => (
                                <SwiperSlide onClick={() => navigate('/gdu-media/materials/'+item.slug)} className='g-post galary g-dfw' key={idx}>
                                    <div className="g-post__banner">
                                        {item.pics? <img src={'/media/' + item.pics[0].pic} alt=""/>: null}
                                    </div>
                                    <div className="g-btn g-btn__white g-count">{item.itemCount}</div>
                                    <div className="g-pe__wrap">
                                        <h2 className="g-pe__title">{item.title}</h2>
                                        <p className="g-pe__text">PDF · {fmtDate(item.createdAt)}</p>
                                    </div>
                                </SwiperSlide>
                            ) )}
                        </Swiper>

                    </div>
                </div>
                {gallery?.pics && gallery.pics.map((item, idx) => (
                    <iframe
                        src="https://dzen.ru/embed/vZ_294WtOO1Q"
                        id={"dzen-clip" + item.id}
                        className="fslightbox-source"
                        width="480" height="270" 
                        allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media" frameBorder="0" scrolling="no" allowFullScreen
                    ></iframe>
                ))}
                
            </section>
        </>
    )
}

export default MaterialsIn;